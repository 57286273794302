<template>
  <b-col v-if="!error" id="loyalty-overview" cols="12" class="mt-5">
    <span class="uppercase-subheadline">{{
      $t("loyalty.overview.subheadline")
    }}</span>
    <b-row id="loyalty-head-row" no-gutters align-v="center" align-h="around">
      <b-col class="pl-3">
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.table.rank_new") }}
        </span>
      </b-col>
      <b-col cols="3">
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.overview.row.title") }}
        </span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center text-center">
        <InfoBadge
          id="card-bonus-info-badge"
          :info-text="$t('info_badge.loyalty.card_bonus')"
        />
        <img
          draggable="false"
          src="@/assets/img/loyalty/dailylogin-card.png"
          style="width: 19px"
          class="mb-2"
        />
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.overview.row.bonus_daily_login") }}
        </span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center text-center">
        <img
          draggable="false"
          src="@/assets/img/common/coin1.png"
          style="width: 28px"
          class="mb-2"
        />
        <InfoBadge
          id="gold-bonus-info-badge"
          :info-text="$t('info_badge.loyalty.gold_bonus')"
        />
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.overview.row.bonus_gold") }}
        </span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center text-center">
        <img
          draggable="false"
          src="@/assets/img/common/crown6.png"
          style="width: 38px"
          class="mb-2"
        />
        <InfoBadge
          id="crown-bonus-info-badge"
          :info-text="$t('info_badge.loyalty.crown_bonus')"
        />
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.overview.row.bonus_crowns") }}
        </span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center text-center">
        <img
          draggable="false"
          src="@/assets/img/nav-menu/nav-icon-cards.png"
          style="width: 30px"
          class="mb-2"
        />
        <InfoBadge
          id="trading-cards-bonus-info-badge"
          :info-text="$t('info_badge.loyalty.trading_cards_bonus')"
        />
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.overview.row.bonus_cards") }}
        </span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center text-center">
        <img
          draggable="false"
          src="@/assets/img/nav-menu/nav-icon-cards.png"
          style="width: 30px"
          class="mb-2"
        />
        <InfoBadge
          id="levelup-bonus-info-badge"
          :info-text="$t('info_badge.loyalty.levelup_bonus')"
        />
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.overview.row.bonus_level_up") }}
        </span>
      </b-col>
      <b-col class="d-flex flex-column align-items-center text-center">
        <InfoBadge
          id="vip-bonus-info-badge"
          :info-text="$t('info_badge.loyalty.vip_bonus')"
        />
        <div style="height: 30px" class="mb-2"></div>
        <span class="text-uppercase grey medium">
          {{ $t("loyalty.overview.row.bonus_support") }}
        </span>
      </b-col></b-row
    >
    <LoyaltyRow :first-row="true" :loyalty-item="itemLevelZero" />
    <LoyaltyRow
      v-for="item in config"
      :key="item.id"
      :loyalty-item="item"
    /> </b-col
  ><ErrorPlaceholder v-else class="w-100" />
</template>
<script>
import LoyaltyRow from "@/components/loyalty/loyalty-row.vue";
import ErrorPlaceholder from "@/components/base/error-placeholder.vue";
import InfoBadge from "@/components/base/info-badge.vue";
export default {
  name: "LoyaltyOverview",
  components: { LoyaltyRow, ErrorPlaceholder, InfoBadge },
  props: {
    config: {
      type: Array,
      required: true,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      itemLevelZero: {
        bonus_card_collect: 0,
        bonus_dailylogin_card: 0,
        bonus_league_rewards: 0,
        bonus_level_up: 0,
        bonus_shop_crowns: 0,
        bonus_shop_gold: 0,
        bonus_support: false,
        id: 0,
        level: 0,
        progress_needed: 0,
        slug: "level_0",
      },
    };
  },
  mounted() {
    if (this.config) {
      this.$store.commit("setIsPageLoading", false);
    }
  },
};
</script>
<style lang="scss">
#loyalty-overview {
  position: relative;
  #loyalty-head-row {
    width: 100%;
    height: 72px;
    margin-bottom: 8px;
    position: relative;
    background-color: $dark-blue-03;

    .grey {
      color: #8e8e8e;
      font-size: 14px;
      line-height: 1.1;
      @media (max-width: 1100px) {
        font-size: 12px;
        font-family: ubuntu;
      }
    }
  }
}

#rank-col {
  max-width: 300px;
}
</style>
