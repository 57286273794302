<template>
  <TabbedPage>
    <template v-slot:main-content>
      <loyalty-contents></loyalty-contents
      ><loyalty-overview :config="config"></loyalty-overview></template
  ></TabbedPage>
</template>

<script>
import TabbedPage from "@/views/TabbedPage.vue";
import LoyaltyContents from "@/components/loyalty/loyalty-contents.vue";
import LoyaltyOverview from "@/components/loyalty/loyalty-overview.vue";

export default {
  name: "LoyaltyPage",
  components: {
    LoyaltyContents,
    LoyaltyOverview,
    TabbedPage,
  },
  data() {
    return {
      loading: true,
      error: false,
    };
  },
  computed: {
    config() {
      return this.$store.state.generalConfig.loyality;
    },
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);
  },
};
</script>
