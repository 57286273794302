<template>
  <b-col id="loyalty-contents" cols="12">
    <div class="position-relative" style="width: 500px">
      <span class="uppercase-subheadline">{{
        $t("loyalty.headline.current_rank")
      }}</span>
      <InfoBadge
        id="loyalty-info-badge"
        :info-text="$t('info_badge.loyalty')"
        faq-link="loyalty"
        faq-article="360002830437"
      />
    </div>

    <b-row no-gutters align-v="center" align-h="between">
      <b-col
        id="current-rank-info-box"
        lg="12"
        xl="7"
        class="d-inline-flex gold-border-box"
      >
        <img
          draggable="false"
          :src="require(`@/assets/img/loyalty/orden_0${loyaltyLevel}.png`)"
          class="badge-img"
        />
        <div class="h-100 ml-5">
          <p class="text-uppercase medium grey m-0">
            {{ $t("loyalty.contents.current_title") }}
          </p>
          <p class="text-uppercase bold yellow">
            {{ $t(`loyalty.title.level_${loyaltyLevel}`) }}
          </p>
          <p class="text-uppercase medium grey m-0">
            {{ $t("loyalty.contents.current_rank") }}
          </p>
          <p class="text-uppercase bold yellow">
            {{ $t(`loyalty.rank.level_${loyaltyLevel}`) }}
          </p>
          <p class="text-uppercase medium grey m-0">
            {{ $t("loyalty.contents.current_points") }}
          </p>
          <div class="points-display d-inline-flex align-items-center">
            <span class="text-white bold ml-3 white-glow">{{
              formatNumber(loyaltyProgress)
            }}</span>
            <div class="points-icon bg-img ml-3"></div>
          </div>
        </div>
        <div class="d-flex flex-column w-50">
          <div class="w-100 text-center mb-3">
            <span class="text-uppercase medium grey">{{
              $t("loyalty.contents.boni")
            }}</span>
          </div>
          <div class="w-100 h-100 d-flex">
            <div
              class="w-50 h-100 d-flex flex-column align-items-center justify-content-between text-center"
            >
              <p class="text-uppercase medium grey small m-0">
                {{ $t("loyalty.overview.row.bonus_daily_login") }}
              </p>
              <div class="d-inline-flex align-items-center">
                <span class="text-white bold ml-3 white-glow"
                  >+{{ dailyLoginCards }}</span
                >
                <div class="card-icon bg-img ml-1"></div>
              </div>

              <p class="text-uppercase medium grey small m-0">
                {{ $t("loyalty.overview.row.bonus_crowns") }}
              </p>
              <div class="d-inline-flex align-items-center">
                <span class="text-white bold ml-3 white-glow"
                  >+{{ bonusCrowns }}%</span
                >
                <div class="crown-icon bg-img ml-1"></div>
              </div>
              <p class="text-uppercase medium grey small m-0">
                {{ $t("loyalty.overview.row.bonus_gold") }}
              </p>
              <div class="d-inline-flex align-items-center">
                <span class="text-white bold ml-3 white-glow"
                  >+{{ bonusGold }}%</span
                >
                <div class="gold-icon bg-img ml-1"></div>
              </div>
            </div>
            <div
              class="w-50 h-100 d-flex flex-column align-items-center justify-content-between text-center"
            >
              <p class="text-uppercase medium grey small m-0">
                {{ $t("loyalty.overview.row.bonus_cards") }}
              </p>
              <div class="d-inline-flex align-items-center">
                <span class="text-white bold ml-3 white-glow"
                  >+{{ bonusCards }}%</span
                >
                <div class="trading-cards-icon bg-img ml-1"></div>
              </div>

              <p class="text-uppercase medium grey small m-0">
                {{ $t("loyalty.overview.row.bonus_level_up") }}
              </p>
              <div class="d-inline-flex align-items-center">
                <span class="text-white bold ml-3 white-glow"
                  >+{{ bonusLevelUp }}%</span
                >
                <div class="trading-cards-icon bg-img ml-1"></div>
              </div>
              <p class="text-uppercase medium grey small m-0">
                {{ $t("loyalty.overview.row.bonus_vir") }}
              </p>
              <div class="d-inline-flex align-items-center">
                <div v-if="bonusSupport" class="support-icon bg-img yes"></div>
                <div v-else class="support-icon bg-img no"></div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col v-if="!maxLevel" lg="12" xl="5" class="pl-xl-3">
        <div id="next-rank-info-box" class="drop-shadow-box">
          <b-row
            no-gutters
            class="progress-bar-section mx-auto"
            :class="{ 'max-level': maxLevel }"
            ><div class="w-100 d-flex">
              <div class="pr-4">
                <img
                  :src="
                    require(`@/assets/img/loyalty/orden_0${
                      loyaltyLevel + 1
                    }.png`)
                  "
                  class="badge-img-small"
                />
              </div>
              <div class="w-75 d-flex flex-column">
                <p class="text-uppercase medium grey m-0">
                  {{ $t("loyalty.contents.next_title") }}
                </p>
                <p class="text-uppercase bold yellow mt-1">
                  {{ $t(`loyalty.title.level_${loyaltyLevel + 1}`) }}
                </p>
                <div class="w-100 d-inline-flex justify-content-between">
                  <div class="w-50">
                    <p class="text-uppercase medium grey m-0">
                      {{ $t("loyalty.contents.next_rank") }}
                    </p>
                    <p class="text-uppercase bold yellow mt-1">
                      {{ $t(`loyalty.rank.level_${loyaltyLevel + 1}`) }}
                    </p>
                  </div>
                  <div class="w-50">
                    <p class="text-uppercase medium grey m-0 text-center">
                      {{ $t("loyalty.contents.points_needed") }}
                    </p>
                    <div class="mt-1 points-container">
                      <div
                        class="points-display d-inline-flex align-items-center"
                      >
                        <span class="text-white bold ml-3 white-glow">{{
                          formatNumber(pointsToAchieve)
                        }}</span>
                        <div class="points-icon bg-img ml-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-100">
              <p class="text-uppercase medium grey m-0">
                {{ $t("tournaments.info.progress") }}
              </p>

              <div class="progress-bar mt-1">
                <div
                  class="progress-bar-fill"
                  :style="{ width: loyaltyProgressPercentage + '%' }"
                ></div>
              </div>
              <p class="text-uppercase blue blue-glow mt-2">
                {{ loyaltyProgressPercentage }}% ({{
                  $t("loyalty.contents.points_togo", {
                    0: formatNumber(pointsTogo),
                  })
                }})
              </p>
            </div>
          </b-row>
        </div>
      </b-col></b-row
    >
  </b-col>
</template>
<script>
import InfoBadge from "@/components/base/info-badge.vue";
export default {
  name: "LoyaltyContents",
  components: { InfoBadge },

  computed: {
    loyalty() {
      return this.$store.getters["user/getUserLoyalty"];
    },
    config() {
      return this.$store.state.generalConfig.loyality;
    },
    nextLevel() {
      if (!this.loyalty) {
        return 1;
      }
      return this.loyalty.level + 1;
    },
    loyaltyLevel() {
      if (!this.loyalty) {
        return 0;
      }
      return this.loyalty.level;
    },
    startingPoints() {
      if (this.config.length > 0) {
        if (this.loyaltyLevel === 0) {
          return 0;
        } else {
          let sum = 0;
          for (let i = this.loyaltyLevel; i >= 1; i--) {
            sum += this.config[i - 1].progress_needed;
          }
          return sum;
        }
      } else {
        return 0;
      }
    },
    pointsToAchieve() {
      if (this.config.length > 0) {
        if (!this.maxLevel) {
          let sum = 0;
          for (let i = this.loyaltyLevel; i >= 0; i--) {
            sum += this.config[i].progress_needed;
          }
          return sum;
        } else {
          return "";
        }
      } else {
        return null;
      }
    },
    pointsTogo() {
      let diff = this.pointsToAchieve - this.loyaltyProgress;
      return diff;
    },
    loyaltyProgress() {
      if (!this.loyalty) {
        return 0;
      } else if (this.loyalty && this.loyalty.loyality) {
        return this.loyalty.progress + this.startingPoints;
      } else {
        return this.loyalty.progress;
      }
    },
    loyaltyProgressPercentage() {
      const max = this.pointsToAchieve - this.startingPoints;
      const target = this.loyaltyProgress - this.startingPoints;

      if (this.loyalty) {
        return Math.floor((100 / max) * target);
      } else {
        return 0;
      }
    },

    maxLevel() {
      return this.loyaltyLevel >= 9;
    },
    indicatorMargin() {
      if (this.loyaltyProgressPercentage <= 99) {
        return { "margin-left": this.loyaltyProgressPercentage + "%" };
      } else {
        return { "margin-left": "99%" };
      }
    },
    dailyLoginCards() {
      if (this.loyaltyLevel === 0) return 0;
      return this.config[this.loyaltyLevel - 1].bonus_dailylogin_card;
    },
    bonusCrowns() {
      if (this.loyaltyLevel === 0) return 0;
      return this.config[this.loyaltyLevel - 1].bonus_shop_crowns;
    },
    bonusGold() {
      if (this.loyaltyLevel === 0) return 0;
      return this.config[this.loyaltyLevel - 1].bonus_shop_gold;
    },
    bonusCards() {
      if (this.loyaltyLevel === 0) return 0;
      return this.config[this.loyaltyLevel - 1].bonus_card_collect;
    },
    bonusLevelUp() {
      if (this.loyaltyLevel === 0) return 0;
      return this.config[this.loyaltyLevel - 1].bonus_level_up;
    },
    bonusSupport() {
      if (this.loyaltyLevel === 0) return 0;
      return this.config[this.loyaltyLevel - 1].bonus_support;
    },
  },
  mounted() {
    if (this.config && this.loyaltyProgressPercentage <= 100) {
      this.$store.commit("setIsPageLoading", false);
    }
  },
};
</script>
<style lang="scss">
#loyalty-contents {
  .grey {
    font-size: 14px;
    color: #8e8e8e;
    &.small {
      font-size: 12px;
      letter-spacing: 0;
      line-height: 1.2;
    }
  }
  .yellow {
    font-size: 21px;
    color: #fff8b7;
    line-height: 1;
  }
  .white-glow {
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
      0 0 8px rgba(77, 229, 255, 0.6), 0 0 8px rgba(77, 229, 255, 0.6),
      0 0 4px rgba(0, 94, 110, 0.8), 0 0 4px rgba(0, 94, 110, 0.8);
  }
  .blue-glow {
    filter: drop-shadow(0 0 16px rgba(77, 229, 255, 0.6))
      drop-shadow(0 0 8px rgba(77, 229, 255, 0.6))
      drop-shadow(0 0 4px rgba(0, 94, 110, 0.8));
  }
  .points-container {
    width: 69%;
    margin-left: auto;
    margin-right: auto;

    .points-display {
      background-color: black;
      border-radius: 8px;
      box-shadow: 0 3px 0 #074f5c;
      padding: 7px;
      height: 40px;
      min-width: 141px;
      span {
        font-size: 24px;
      }
      .points-icon {
        width: 25px;
        height: 25px;
        background-image: url(~@/assets/img/common/icon-loyalty.png);
      }
    }
  }

  .card-icon {
    width: 13px;
    height: 21px;
    background-image: url(~@/assets/img/loyalty/dailylogin-card.png);
  }
  .crown-icon {
    width: 28px;
    height: 21px;
    background-image: url(~@/assets/img/common/crown6.png);
    filter: drop-shadow(0 2px 3px black);
  }
  .gold-icon {
    width: 21px;
    height: 21px;
    background-image: url(~@/assets/img/common/coin1.png);
  }
  .trading-cards-icon {
    width: 25px;
    height: 25px;
    background-image: url(~@/assets/img/nav-menu/nav-icon-cards.png);
  }
  .support-icon {
    width: 21px;
    height: 21px;
    background-image: url(~@/assets/img/common/check-green.svg);
    &.no {
      background-image: url(~@/assets/img/common/check-red.svg);
    }
  }
  #current-rank-info-box {
    height: 247px;
    padding: 26px 33px 29px 41px;
  }
  #next-rank-info-box {
    height: 247px;
    padding: 21px 27px 27px 27px;

    @media (max-width: 1279px) {
      margin-top: 40px;
    }
  }

  .badge-img {
    max-height: 192px;
  }
  .badge-img-small {
    width: 59px;
    height: 81px;
    &.shadow-badge {
      position: absolute;
      top: -3px;
    }
  }

  .badge-large-empty {
    height: 192px;
    background: url(~@/assets/img/loyalty/orden_00.png) 50% 3%/150px 205px
      no-repeat;
  }

  .progress-bar-section {
    &.max-level {
      height: 100px;
    }

    .progress-bar {
      height: 8px;
      width: 100%;
      max-width: 467px;
      position: relative;
      background-color: rgba(77, 229, 255, 0.1);
      overflow: visible;

      .progress-bar-fill {
        height: 100%;
        background-color: $light-blue;
        filter: drop-shadow(0 0 8px rgba(77, 229, 255, 0.58))
          drop-shadow(0 0 8px rgba(77, 229, 255, 0.58))
          drop-shadow(0 0 4px rgba(77, 229, 255, 0.58));
      }
    }
  }
}
</style>
