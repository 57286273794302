<template>
  <b-row
    no-gutters
    align-v="center"
    align-h="around"
    class="loyalty-row"
    :class="highlightCurrentLevel"
  >
    <b-col class="pl-3">
      <span class="loyalty-title bold text-uppercase">{{
        $t(`loyalty.rank.level_${loyaltyItem.level}`)
      }}</span>
    </b-col>
    <b-col cols="3" class="loyalty-badge d-flex align-items-center ml-1">
      <img
        draggable="false"
        :src="require(`@/assets/img/loyalty/orden_0${loyaltyItem.level}.png`)"
      />
      <div
        class="loyalty-title bold text-uppercase ml-3"
        :class="[
          { smol: loyaltyTitle.length > 20 },
          { smoller: loyaltyTitle.length > 25 },
        ]"
      >
        {{ loyaltyTitle }}
      </div>
    </b-col>
    <b-col class="bonus-item-number text-center">
      +{{ loyaltyItem.bonus_dailylogin_card }}
    </b-col>
    <b-col class="bonus-item-number text-center">
      {{ loyaltyItem.bonus_shop_gold }}%
    </b-col>
    <b-col class="bonus-item-number text-center">
      {{ loyaltyItem.bonus_shop_crowns }}%
    </b-col>
    <b-col class="bonus-item-number text-center">
      {{ loyaltyItem.bonus_card_collect }}%
    </b-col>
    <b-col class="bonus-item-number text-center">
      {{ loyaltyItem.bonus_level_up }}%
    </b-col>
    <b-col class="d-flex justify-content-center">
      <img
        draggable="false"
        :src="yepOrNope"
        style="width: 26px; height: 26px"
      />
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "LoyaltyRow",
  props: {
    loyaltyItem: {
      type: Object,
      required: true,
    },
    firstRow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    yepOrNope() {
      if (this.loyaltyItem.bonus_support) {
        return require("@/assets/img/common/check-green.svg");
      } else {
        return require("@/assets/img/common/check-red.svg");
      }
    },
    highlightCurrentLevel() {
      let loyalty = this.$store.getters["user/getUserLoyalty"];
      let currentLevel = loyalty.level;

      if (currentLevel === this.loyaltyItem.level) {
        return "highlight";
      } else {
        return "";
      }
    },
    loyaltyTitle() {
      return this.$t(`loyalty.title.level_${this.loyaltyItem.level}`);
    },
  },
};
</script>

<style lang="scss">
.loyalty-row {
  width: 100%;
  height: 78px;
  margin-bottom: 8px;
  position: relative;
  background-color: $dark-blue-06;
  overflow: visible;

  &.highlight {
    border: 2px solid white;
    box-shadow: 0 0 26px rgba(77, 229, 255, 0.6),
      0 0 13px rgba(77, 229, 255, 0.6), 0 0 13px rgba(77, 229, 255, 0.6),
      0 0 6px rgba(0, 94, 110, 0.8);
  }

  .loyalty-badge {
    img {
      width: 47px;
    }
  }

  .loyalty-title {
    font-size: 18px;
    color: #fff8b7;
    line-height: 1;
    @media (max-width: 1400px) {
      &.smol {
        font-size: 16px;
      }
      &.smoller {
        font-size: 14px;
      }
    }
    @media (max-width: 1100px) {
      &.smol {
        font-size: 14px;
      }
      &.smoller {
        font-size: 12px;
      }
    }
  }

  .bonus-item-number {
    font-size: 28px;
    font-family: Ubuntu-bold;
    color: white;
    text-shadow: 0 0 16px rgba(77, 229, 255, 0.6),
      0 0 8px rgba(77, 229, 255, 0.6), 0 0 4px rgba(0, 94, 110, 0.8);
    @media (max-width: 1100px) {
      font-size: 22px;
    }
  }
}
</style>
